.rw-words-1 span {
  -webkit-animation: rotateword 30s linear infinite 0s;
  -ms-animation: rotateword 30s linear infinite 0s;
  animation: rotateword 30s linear infinite 0s;
}
.rw-words-1 span:nth-child(2) {
  -webkit-animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.rw-words-1 span:nth-child(3) {
  -webkit-animation-delay: 5s;
  -ms-animation-delay: 5s;
  animation-delay: 5s;
}
.rw-words-1 span:nth-child(4) {
  -webkit-animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  animation-delay: 7.5s;
}
.rw-words-1 span:nth-child(5) {
  -webkit-animation-delay: 10s;
  -ms-animation-delay: 10s;
  animation-delay: 10s;
}
.rw-words-1 span:nth-child(6) {
  -webkit-animation-delay: 12.5s;
  -ms-animation-delay:  12.5s;
  animation-delay:  12.5s;
}
.rw-words-1 span:nth-child(7) {
  -webkit-animation-delay: 15s;
  -ms-animation-delay: 15s;
  animation-delay: 15s;
}
.rw-words-1 span:nth-child(8) {
  -webkit-animation-delay: 17.5s;
  -ms-animation-delay: 17.5s;
  animation-delay: 17.5s;
}
.rw-words-1 span:nth-child(9) {
  -webkit-animation-delay: 20s;
  -ms-animation-delay: 20s;
  animation-delay: 20s;
}
.rw-words-1 span:nth-child(10) {
  -webkit-animation-delay: 22.5s;
  -ms-animation-delay: 22.5s;
  animation-delay: 22.5s;
}
.rw-words-1 span:nth-child(11) {
  -webkit-animation-delay: 25s;
  -ms-animation-delay: 25s;
  animation-delay: 25s;
}
.rw-words-1 span:nth-child(12) {
  -webkit-animation-delay: 27.5s;
  -ms-animation-delay: 27.5s;
  animation-delay: 27.5s;
}

@keyframes rotateword {
  0%   { opacity: 0; }
  1%   { opacity: 0; -webkit-transform: translateY(-30px); }
  3%   { opacity: 1; -webkit-transform: translateY(0px); }
  8%   { opacity: 1; -webkit-transform: translateY(0px); }
  9%   { opacity: 0; -webkit-transform: translateY(30px); }
  80%  { opacity: 0; }
  100% { opacity: 0; }
}



img {
  position: absolute;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  -o-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
}
@keyframes img1 {
  0% {opacity:1;}
  28% {opacity:1;}
  30% {opacity:0;}
  99% {opacity:0;}
  100% {opacity:1;}
}
@keyframes img2 {
  0% {opacity:0;}
  30% {opacity:0;}
  32% {opacity:1;}
  52% {opacity:1;}
  54% {opacity:0;}
  100% {opacity:0;}
}
@keyframes img3 {
  0% {opacity:0;}
  54% {opacity:0;}
  56% {opacity:1;}
  76% {opacity:1;}
  78% {opacity:0;}
  100% {opacity:0;}
}
@keyframes img4 {
  0% {opacity:0;}
  78% {opacity:0;}
  80% {opacity:1;}
  95% {opacity:1;}
  98% {opacity:0;}
  100% {opacity:0;}
}
img.img1 {
  opacity:1;
  animation-name: img1;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
img.img2 {
  opacity:0;
  animation-name: img2;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
img.img3 {
  opacity:0;
  animation-name: img3;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
img.img4 {
  opacity:0;
  animation-name: img4;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
div.logos:nth-child(1) img.img1 {
  max-width: 65px;
  animation-delay: 0s;
  animation-duration: 40s;
}
div.logos:nth-child(1) img.img2 {
  max-width: 80px;
  animation-delay: 0s;
  animation-duration: 40s;
}
div.logos:nth-child(1) img.img3 {
  max-width: 82px;
  animation-delay: 0s;
  animation-duration: 40s;
}
div.logos:nth-child(1) img.img4 {
  max-width: 90px;
  animation-delay: 0s;
  animation-duration: 40s;
}

div.logos:nth-child(2) img.img1 {
  max-width: 72px;
  animation-delay: 3s;
  animation-duration: 40s;
}
div.logos:nth-child(2) img.img2 {
  max-width: 90px;
  animation-delay: 3s;
  animation-duration: 40s;
}
div.logos:nth-child(2) img.img3 {
  max-width: 90px;
  animation-delay: 3s;
  animation-duration: 40s;
}
div.logos:nth-child(2) img.img4 {
  max-width: 24px;
  animation-delay: 3s;
  animation-duration: 40s;
}

div.logos:nth-child(3) img.img1 {
  max-width: 80px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(3) img.img2 {
  max-width: 75px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(3) img.img3 {
  max-width: 70px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(3) img.img4 {
  max-width: 70px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(4) img.img1 {
  max-width: 100px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(4) img.img2 {
  max-width: 75px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(4) img.img3 {
  max-width: 100px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(4) img.img4 {
  max-width: 85px;
  animation-delay: 5s;
  animation-duration: 40s;
}
div.logos:nth-child(5) img.img1 {
  max-width: 45px;
  animation-delay: 2s;
  animation-duration: 40s;
}
div.logos:nth-child(5) img.img2 {
  max-width: 40px;
  animation-delay: 2s;
  animation-duration: 40s;
}
div.logos:nth-child(5) img.img3 {
  max-width: 96px;
  animation-delay: 2s;
  animation-duration: 40s;
}
div.logos:nth-child(5) img.img4 {
  max-width: 90px;
  animation-delay: 2s;
  animation-duration: 40s;
}
div.logos:nth-child(6) img.img1 {
  max-width: 85px;
  animation-delay: 4s;
  animation-duration: 40s;
}
div.logos:nth-child(6) img.img2 {
  max-width: 90px;
  animation-delay: 4s;
  animation-duration: 40s;
}
div.logos:nth-child(6) img.img3 {
  max-width: 92px;
  animation-delay: 4s;
  animation-duration: 40s;
}
div.logos:nth-child(6) img.img4 {
  max-width: 80px;
  animation-delay: 4s;
  animation-duration: 40s;
}
