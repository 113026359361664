@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @import "./button.css";
  @import "./carousel.css";
  @import "./guides.css";
  @import "./terms.css";
  @import "./anim.css";
  @import "./embla.css";
  @import "./pin.css";

  .no-margin > div {
    @apply ml-0 mr-0 ;
  }

  img[alt]:after {  
    @apply absolute top-0 left-0 block w-full h-full text-center bg-white-dark2;
    content: attr(alt);
  }

  .blink{
   animation-duration: 1.4s;
   animation-name: blink;
   animation-iteration-count: infinite;
   transition: none;
  }
  @keyframes blink {
    0% {opacity:1;}
    40% {opacity:0.3;}
    100% {opacity:1;}
  }
  .h-search {
    height: calc(100vh - 134px);
  }
  .h-search-mobile {
    --heightA: calc(var(--vh, 1vh) * 100);
    --heightB: calc(var(--heightA) - 135px);
    height: var(--heightB);
  }
  .h-screen-mobile {
    --heightA: calc(var(--vh, 1vh) * 100);
    height: var(--heightA);
  }
  .h-filter {
    height: calc(100vh - 9.5rem);
  }
  .h-filter-in {
    height: calc(100vh - 18.5rem);
  }
  .h-filter-mobile {
    height: calc((var(--vh, 1vh) * 100) - 8.5em);
  }
  .w-map {
    width: calc(100% + 0.5rem);
  }
  .p strong {
    @apply font-normal text-black;
  }
  .p a {
    @apply font-normal text-blue;
  }
  .shadow-partners {
    box-shadow: inset 25px 0px 25px -25px rgba(0,0,0,0.1), inset -25px 0px 25px -25px rgba(0,0,0,0.1);
  }
  .gradient-towhite {
    background: linear-gradient(to bottom,rgba(255,255,255,0) 0%,#fff 100%);
  }
  .gradient-top {
    background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.55) 23%, rgba(0,0,0,0) 100%);
  }
  .gradient-bottom {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 100%);
  }
  nav.logo-max svg.max {
    @apply block;
  }
  nav.logo-min svg.min {
    @apply block;
  }
  nav.logo-min div.search {
    @apply block;
    @apply text-black fill-current;
  }
  .shadow-catridge {
    -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.18);
    -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,.18);
    box-shadow: 0 0 20px 0 rgba(0,0,0,.12);
  }
  .shadow-button {
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
    box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
  }
  .shadow-light {
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
    -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
  }
  .shadow-guide {
    -webkit-box-shadow: 0 0 30px 0 rgba(0,0,0,05);
    -moz-box-shadow: 0 0 30px 0 rgba(0,0,0,.05);
    box-shadow: 0 0 30px 0 rgba(0,0,0,.05);
  }
  .shadow-small {
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,07);
    -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
  }
  .column-guide {
    column-count: 2;
    column-gap: 4em;
  }
  .column-guide a {
  }
  .column-insurance {
    column-count: 2;
    column-gap: 4em;
  }
  .column-insurance p {
    @apply text-dark;
  }
  @media screen and ( max-width: 768px ) {
    .column-guide  {
      column-count: 1;
      column-gap: 2em;
    }
    .column-insurance  {
      column-count: 1;
      column-gap: 2em;
    }
    .modal .modal-h1 {
      @apply pt-8 pb-0 text-xl text-left text-black;
    }
    .modal .modal-icon {
      @apply hidden mt-0;
    }
    .modal .modal-bottom {
      @apply rounded-b-2xl;
    }
  }
  .switch {
    border-color: transparent;
  }
  a:hover {
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
  }
  #checkbox:checked ~ .btn-change::before {
     transition: 0.3s;
     transform: translateX(23px);
  }
  .btn-change {
     background-color: var(--bg-btn);
  }
  .btn-change::before {
     content: '';
     display: block;
     width: 17px;
     height: 17px;
     border-radius: 50%;
     background-color: var(--btn-color);
     transition: 0.3s;
     transform: translateX(0);
  }
  img.dynamic {
    height: inherit;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  div.has-loaded img.dynamic {
    opacity: 1;
  }

  img.avatar {
    object-fit: cover;
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }
  nav.logo-max a.top:hover {
    @apply text-black;
  }
  nav.logo-min a.top:hover {
    @apply text-black;
  }
  nav.scrolled {
    @apply shadow-2xl;
    border-bottom: 0px;
  }
  button {
    outline: none !important;
  }
  input[type='checkbox'] {
    @apply text-blue;
  }
  input[type='checkbox']:hover {
    @apply border-dark;
  }
  input[type='checkbox']:focus {
    @apply ring-0;
  }
  input[type='checkbox']:checked {
    @apply border-blue !important;
    background-image: url(data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e);
  }
  input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(https://officeriders-imager.s3.eu-west-1.amazonaws.com/svg/close.svg) no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }

  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: .3;
    pointer-events: all;
  }

  .zoom {
    @apply w-10 h-20 mt-3 mt-24 mr-3 overflow-hidden text-center bg-white rounded-lg cursor-pointer shadow-button lg:mt-6 lg:mr-6;
  }

  .zoom-popin {
    @apply w-10 h-20 mt-16 mr-3 overflow-hidden text-center bg-white rounded-lg cursor-pointer shadow-button lg:mt-16 lg:mr-3;
  }

  .zoom-in, .zoom-out {
    @apply flex items-center justify-center w-10 h-10 transition hover:bg-blue-light;
  }

  .zoom-in:hover svg, .zoom-out:hover svg {
    @apply fill-current text-blue;
  }

  .separator {
    @apply mx-2 h-[1px] bg-white-dark4;
  }

  .transition-bg {
    transition-property: background-color;
  }
  .link-anim {
    @apply text-black;
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 5px;
    padding-top: 0px;
  }
  .link-anim.active::after {
    content: '';
    display: block;
    height: 3px;
    background: #000;
    width: 100%;
  }
  .link-anim:hover::after {
    width: 100%;
  }
  .link-anim::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #000;
    transition: width .3s;
  }
  .blur {
    transition: filter 0.5s ease-in-out;
    filter: blur(8px);
  }
  .blur-out {
    filter: blur(0px);
  }
  .blurcovid {
    -webkit-backdrop-filter: saturate(200%) blur(17px) brightness(1.2);
    backdrop-filter: saturate(200%) blur(17px) brightness(1.2);
  }
  .content-popin {
    height: calc(80vh - 70px);
  }
  input {
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
  }
  input:focus {
    @apply ring-blue;
  }
  input:focus-within ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-6;
  }
  input:focus-within ~ label {
    @apply text-blue;
  }
  input.sm:focus-within ~ label,
  input.sm:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-5;
  }
  textarea:focus-within ~ label,
  textarea:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-6;
  }
  textarea:focus ~ .label-text {
    @apply text-blue;
  }

  .input-multiline {
    display: block;
    width: 100%;
    height: auto;
    min-height: 100px; /* Hauteur minimale */
    padding: 8px;
    border: 1px solid #ccc;
    resize: vertical; /* Permettre le redimensionnement vertical */
    overflow-y: auto; /* Ajouter une barre de défilement si nécessaire */
    white-space: pre-wrap; /* Permettre le saut de ligne */
  }

  td[data-disabled="true"]:not(:empty) {
    @apply text-white-dark4;
    pointer-events: none;
  }

  div.calendar table td[data-disabled="true"]:not(:empty) {
    pointer-events: none;
  }
  img {
    color: transparent;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
  }
  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
}
.bookmark-container{
    min-height: calc(100vh - 5rem);
}

.show {
  animation: fade_in_show 1s
}

@keyframes fade_in_show {
  0% {
       opacity: 0;
  }

  100% {
       opacity: 1;
  }
}
/* 
.subtitle:after {
  content: url(/svg/storm.svg);
  @apply relative hidden w-3 h-3 ml-2 lg:ml-3 md:inline-block lg:h-4 lg:w-4 top-1 lg:top-2;
} */

.title_plus:after {
  content: url(/svg/external_link.svg);
  @apply relative inline-block w-6 h-6 ml-2 lg:h-7 lg:w-7 lg:top-1;
}

.host-icon:after {
  content: url(/svg/love_host.svg);
  @apply relative inline-block w-6 h-6 left-1 -top-2 lg:-top-4 lg:h-9 lg:w-9;
}

.underline-hand {
  @apply relative lg:font-saol lg:font-bold;
}

.underline-hand:after {
  @apply absolute hidden w-8 h-8 lg:block lg:left-10 md:left-5 md:bottom-2 lg:bottom-3;
}

.underline-hand-2 {
  @apply relative;
}

.gradient-text-red {
  @apply bg-gradient-to-r from-[#FF7C7C] to-[#FF5D5D];
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.gradient-elipse {
  background: radial-gradient(ellipse at 50% 50%, rgba(41, 116, 255, 0.15) 0%, rgba(255, 255, 255, 0) 62%);
}

.gradient-radial-red {
  background: radial-gradient(circle at 50% 50%, rgba(255, 80, 80, 0.08) 0%, rgba(255, 255, 255, 0) 70%);
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background span {
  width: 50vmin;
  height: 50vmin;
  border-radius: 50vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 16;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #F8EBEB;
  top: 5%;
  left: 33%;
  animation-duration: 308s;
  animation-delay: -82s;
  transform-origin: -20vw -2vh;
  box-shadow: -100vmin 0 13.20850668342266vmin currentColor;
}
.background span:nth-child(1) {
  color: #F8EBEB;
  top: 71%;
  left: 19%;
  animation-duration: 192s;
  animation-delay: -130s;
  transform-origin: -6vw 8vh;
  box-shadow: -100vmin 0 12.608124367701404vmin currentColor;
}
.background span:nth-child(2) {
  color: #F8EBEB;
  top: 66%;
  left: 24%;
  animation-duration: 338s;
  animation-delay: -154s;
  transform-origin: -22vw 19vh;
  box-shadow: -100vmin 0 12.547747836818251vmin currentColor;
}
.background span:nth-child(3) {
  color: #F8EBEB;
  top: 33%;
  left: 85%;
  animation-duration: 118s;
  animation-delay: -152s;
  transform-origin: -21vw 14vh;
  box-shadow: -100vmin 0 12.529813032039982vmin currentColor;
}
.background span:nth-child(4) {
  color: #F8EBEB;
  top: 61%;
  left: 8%;
  animation-duration: 143s;
  animation-delay: -47s;
  transform-origin: 9vw 14vh;
  box-shadow: -100vmin 0 12.780506272865194vmin currentColor;
}
.background span:nth-child(5) {
  color: #F8EBEB;
  top: 59%;
  left: 8%;
  animation-duration: 12s;
  animation-delay: -118s;
  transform-origin: 18vw 24vh;
  box-shadow: 100vmin 0 12.7294817068956vmin currentColor;
}
.background span:nth-child(6) {
  color: #F8EBEB;
  top: 28%;
  left: 82%;
  animation-duration: 50s;
  animation-delay: -28s;
  transform-origin: -23vw 16vh;
  box-shadow: -100vmin 0 12.665041327430025vmin currentColor;
}
.background span:nth-child(7) {
  color: #F8EBEB;
  top: 12%;
  left: 6%;
  animation-duration: 207s;
  animation-delay: -89s;
  transform-origin: 13vw 12vh;
  box-shadow: -100vmin 0 12.999492408332273vmin currentColor;
}

.background span:nth-child(3) {
  color: #F8EBEB;
  top: 34%;
  left: 11%;
  animation-duration: 153s;
  animation-delay: -50s;
  transform-origin: -10vw -10vh;
  box-shadow: -100vmin 0 13.01586932115298vmin currentColor;
}
.background span:nth-child(4) {
  color: #F8EBEB;
  top: 75%;
  left: 79%;
  animation-duration: 29s;
  animation-delay: -138s;
  transform-origin: -3vw 0vh;
  box-shadow: 100vmin 0 13.07527595819929vmin currentColor;
}
.background span:nth-child(5) {
  color: #F8EBEB;
  top: 64%;
  left: 4%;
  animation-duration: 227s;
  animation-delay: -90s;
  transform-origin: -20vw -23vh;
  box-shadow: 100vmin 0 12.604178431669277vmin currentColor;
}

.markdown ul {
  @apply list-disc list-inside;
}

div.breadcrumb-content a {
  @apply text-black hover:text-blue;
}

.saol {
  @apply font-bold font-saol;
}

.active {
  @apply active:scale-95 active:transition;
}

input[type="search"]
{
  background: transparent;
  border: none;
}

html {
  scroll-behavior: smooth;
}
