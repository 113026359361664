.carousel {
background: transparent;
}
.carousel-cell {
  height: 380px;
  @apply w-3/9 mr-3 rounded-lg;
  counter-increment: carousel-cell;
}
.carousel-cell-search {
  height: 100%;
  @apply w-full mr-3 rounded-lg;
  counter-increment: carousel-cell;
}
.carousel-guide{
  height: 360px;
  @apply min-w-450 rounded-lg;
  counter-increment: carousel-cell;
}
.carousel-cell-home-partners {
  height: 400px;
  counter-increment: carousel-cell;
}
.carousel-cell-services-partners {
  height: 400px;
  @apply w-full mr-3 rounded-lg;
  counter-increment: carousel-cell;
}
.carousel-cell-space {
  height: 500px;
  @apply w-50v mr-2 rounded-lg;
  counter-increment: carousel-cell;
}
.carousel-cell-space-open {
  height: 500px;
  @apply rounded-lg;
  counter-increment: carousel-cell;
}
.carousel-cell-space-home {
  height: 380px;
  @apply rounded-lg;
  counter-increment: carousel-cell;
}
@media screen and ( max-width: 768px ) {
  .carousel-cell-space {
    @apply w-5/7;
    height: 380px;
  }
  .carousel-cell-space-home { height: 300px;}
}

@media screen and ( max-width: 425px) {
  .carousel-cell { height: 280px; width: 80%; }
  .carousel-cell-home-partners { height: 320px }
  .flickity-button {
    display: none;
  }
  .carousel-cell-space {
    @apply w-6/7;
    height: 280px;
  }
}

.carousel-cell-search {
  height: 280px;
  min-height: 100px;
  @apply w-full;
  counter-increment: carousel-cell;
}

.carousel-content-search ol.flickity-page-dots { bottom: 10px; }
.carousel-content-search ol.flickity-page-dots li.dot {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin: 0 5px;
}


.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-button {
  position: absolute;
  background: white;
  border: none;
  color: #08080a;
}
.flickity-button:hover {
  background: white;
  cursor: pointer;
}
.flickity-button:focus {
  outline: none;
}
.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}
.flickity-button-icon {
  fill: #08080a;
}
.flickity-button:hover .flickity-button-icon {
  fill: #4e91f9;
}
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}
.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 28%;
  top: 28%;
  width: 42%;
  height: 42%;
}
.flickity-page-dots {
position: absolute;
width: 100%;
bottom: -25px;
padding: 0;
margin: 0;
list-style: none;
text-align: center;
line-height: 1;
}
.flickity-rtl .flickity-page-dots {
direction: rtl;
}
.flickity-page-dots .dot {
display: inline-block;
width: 10px;
height: 10px;
margin: 0 8px;
background: #74747b;
border-radius: 50%;
opacity: 0.25;
cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
opacity: 1;
}

.carousel-cell-image {
  filter: blur(6px);
}

