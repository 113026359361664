.embla {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  --slide-spacing: 1rem;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__container {
  height: 100%;
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
  min-width: 50%;
}

.embla__slide__space__2 {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
}

.embla__slide__partner{
  position: relative;
  padding-left: 10px;
  flex: 0 0 40%;
}
.embla__slide__partner__home{
  position: relative;
  padding-left: 15px;
  flex: 0 0 19%;
}
.embla__slide__space {
  position: relative;
  min-width: 30%;
  padding-left: 16px;
}
.embla__slide__space__tags {
  position: relative;
  min-width: 30%;
  padding-left: 10px;
}
.embla__slide__search{
  position: relative;
  flex: 0 0 100%;
}

.embla-slide-home {
  @apply min-w-[75%] lg:min-w-[29%] xl:min-w-[25%] 2xl:min-w-[21%] relative;
}

.embla-slide-cities {
  @apply min-w-[85%] md:min-w-[56%] xl:min-w-[38%] 2xl:min-w-[35%] relative;
}

.prev-next > div > div > div > .arrow {
  visibility: hidden;
}
.prev-next-hover > div > div > div > .arrow {
  visibility: visible;
}

@media screen and ( max-width: 768px ) {
  .embla__viewport:before {
    display: none;
    content: '{"slidesToScroll": 1}';
  }
  .embla__prev{
    display: none;
  }
  .embla__next{
    display: none;
  }
  .embla__slide {
    min-width: 85%;
  }
  .embla__slide__space__tags {
    min-width: 85%
  }
  .embla__slide__space {
    min-width: 85%
  }
  .embla__slide__partner{
    min-width: 85%
  }
  .embla__slide__partner__home{
    min-width: 85%
  }
}
