section.terms h2 {
  @apply pb-2 text-black;
}
section.terms h3 {
  @apply pb-2 text-dark-deep;
}

section.terms h4 {
  @apply pb-2 text-dark-deep;
}

section.terms ul {
  @apply pb-6;
}

section.terms p {
  @apply pb-6;
}

section.terms strong {
  @apply font-normal;
  filter: brightness(30%);
}

section.terms {
  column-count: 2;
  column-gap: 4em;
}

@media screen and ( max-width: 768px ) {
  section.terms {
    column-count: 1;
    column-gap: 2em;
  }
}
