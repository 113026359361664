button {
  @apply outline-none rounded-lg transition duration-300 ease-in-out;
}
button:active {
  @apply outline-none;
}
button:focus {
  @apply outline-none;
}
button svg {
  @apply transition-none;
}
.blue-gradient {
  @apply transition text-white bg-gradient-to-r from-[#4C8AFF] to-blue tracking-wide;
}
.blue-gradient:hover {
  @apply text-white bg-gradient-to-r from-blue to-blue ;
}
.red-gradient {
  @apply transition text-white bg-gradient-to-r from-red-light2 to-red tracking-wide;
}
.red-gradient:hover {
  @apply text-white bg-gradient-to-r from-red to-red ;
}
.blue {
  @apply text-white bg-blue;
}
.blue:hover {
  @apply bg-blue-dark;
}
.black {
  @apply text-white bg-black;
}
.black:hover {
  @apply bg-black-light;
}
.toblue {
  @apply text-black-light;
}
.toblue:hover {
  @apply shadow-none text-blue bg-blue-light;
}
.gray {
  @apply bg-white-dark2 text-black;
}
.gray:hover {
  @apply shadow-none text-blue bg-blue-light;
}
.stroke {
  @apply text-black border border-black;
}
.stroke:hover {
  @apply text-white bg-black;
}
.period-0 {
  @apply bg-blue-light;
}
.period-0 span, .period-1 span, .period-2 span, .period-3 span {
  @apply text-blue;
}
.period-1 {
  background: -webkit-linear-gradient(125deg,#eef4ff 50%,#fff 50%,#fff 100%);
  background: linear-gradient(125deg,#eef4ff 50%,#fff 50%,#fff 100%);
}
.period-1:hover {
  background: -webkit-linear-gradient(125deg,#eef4ff 50%,#f7f7f9 50%,#f7f7f9 100%);
  background: linear-gradient(125deg,#eef4ff 50%,#f7f7f9 50%,#f7f7f9 100%);
}
.period-2 {
  background: -webkit-linear-gradient(125deg,#fff 0%,#fff 50%,#eef4ff 50%,#eef4ff 100%);
  background: linear-gradient(125deg,#fff 0%,#fff 50%,#eef4ff 50%,#eef4ff 100%);
}
.period-2:hover {
  background: -webkit-linear-gradient(125deg,#f7f7f9 0%,#fff 50%,#eef4ff 50%,#eef4ff 100%);
  background: linear-gradient(125deg,#f7f7f9 0%,#f7f7f9 50%,#eef4ff 50%,#eef4ff 100%);
}
.period-3 {
  background: radial-gradient(ellipse at top left,#fff 65%,#eef4ff 65%,#eef4ff 65%);
  background: -webkit-radial-gradient(ellipse at top left,#fff 65%,#eef4ff 65%,#eef4ff 65%);
}
.period-3:hover {
  background: radial-gradient(ellipse at top left,#f7f7f9 65%,#eef4ff 65%,#eef4ff 65%);
  background: -webkit-radial-gradient(ellipse at top left,#f7f7f9 65%,#eef4ff 65%,#eef4ff 65%);
}
