div.guide-content h2 {
  @apply text-black pb-2;
}
div.guide-content p {
  @apply pb-6;
}
div.guide-content strong {
  @apply font-normal;
  filter: brightness(30%);
}
div.guide-content p a {
  @apply text-black hover:text-blue;
}
