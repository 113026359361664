div[class*="custom-clustericon"] div {
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
  @apply transition h-8 w-8 -mt-4 -ml-4 text-sm font-sans flex items-center justify-center rounded-full bg-white text-black font-medium !important;
}

.gm-style {
  font-family: 14px gilroy;
}

div[class*="custom-clustericon"] div:hover {
  @apply h-8 w-8 text-sm font-sans   rounded-full text-white bg-blue font-medium hover:scale-110 !important;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
}

div[id*="pin"] {
  @apply w-full h-full;
}

div[id*="pin"] > span.text {
  @apply font-sans font-medium text-sm cursor-pointer flex items-center justify-center bg-white rounded-full -mt-4 -ml-4 h-8 w-8 px-3 transition hover:bg-blue hover:text-white hover:scale-110;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
}

div[style*="z-index"] > div[id*="pin_S"] > span.text {
  @apply font-sans font-medium text-sm cursor-pointer flex items-center justify-center bg-white rounded-full -mt-4 -ml-8 h-8 w-16 max-w-[55px] px-3 transition bg-blue text-white scale-110;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
}

div[id*="pin_S"] > span.text {
  @apply font-sans font-medium text-sm cursor-pointer flex items-center justify-center bg-white rounded-full -mt-4 -ml-8 h-8 w-16 max-w-[55px] px-3 transition hover:bg-blue hover:text-white hover:scale-110;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);
}

div[id*="pin_opengroup"] > span.text {
  @apply hidden;
}

div[class*="custom-clustericon"] img {
  display: none;
}

div[class*="custom-clustericon"] {
  @apply transition border-none flex items-center justify-center;
}

div.cluster-hover > div {
  @apply bg-blue text-white scale-110 !important;
}

div.hover > span.text {
  @apply bg-blue text-white scale-110;
}

.popin-search-responsive {
  --heightScreen: calc(var(--vh, 1vh) * 100);
}
